
// Register Service Worker
if ('serviceWorker' in navigator) {
  navigator.serviceWorker.register('/service-worker.js')
    .catch(console.error)
}

// Track "Add to Homescreen" prompts
window.addEventListener('beforeinstallprompt', e => {
  e.userChoice.then(choiceResult => {
    if (typeof ga !== "undefined") {
      ga('send', 'event', 'PWA Install', choiceResult.outcome);
    }
  });
});
